<template>
  <div>
    <v-row class="text-center">
      <v-col cols="12" class="">
        <v-card
          class="mx-auto pa-4"
          style="max-width: 700px; height: auto; width: 95%"
        >
          <v-card-title class="d-flex justify-center">
            <v-row>
              <v-col cols="12">
                <v-icon size="60" color="#4ca3ad"
                  >mdi-checkbox-marked-circle</v-icon
                >
              </v-col>
              <v-col cols="12">
                <span style="font-weight: bold">คำสั่งซื้อสำเร็จ </span>
                <br />
                <span style="font-size: 16px"
                  >ล็อตเตอรี่ออนไลน์ จ่ายโดยรัฐบาล</span
                >
              </v-col>
            </v-row>
          </v-card-title>

          <v-card class="pa-4 mx-5">
            <span style="font-size: 18px; font-weight: bold"
              >ขอบคุณสำหรับคำสั่งซื้อ</span
            >
            <br />
            <span style="font-size: 16px">หมายเลขคำสั่งซื้อของคุณคือ</span>
            <br />
            <span style="font-size: 40px; font-weight: bold">1919199119</span>
            <v-img
              width="100px"
              height="85px"
              src="../assets/logo/Logo1.jpg"
              alt="logoDragon"
              class="mx-auto"
              style="max-width: 100%"
            />
            <span style="font-size: 16px">หมายเลขลอตเตอรี่ของคุณคือ</span>
            <br />
            <span style="font-size: 24px; font-weight: bold">30303030</span>
            <br />
            <span style="font-size: 14px">วันที่ | เวลา</span>
            <br />
            <span style="font-size: 18px; font-weight: bold"
              >ลอตเตอรี่จำนวน 99 ใบ</span
            >
          </v-card>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  components: {},

  data: () => ({
    selectTabs: 0,
    setAmtAll: 0,

    selectedItems: [],

    isPaid: false,
  }),

  watch: {},
  computed: {
    carouselItems() {
      return this.selectedItems.flatMap((item) =>
        Array(item.setAmt).fill(item)
      );
    },
  },

  created() {
    const storedItems = localStorage.getItem("selectedItems");
    this.selectedItems = storedItems ? JSON.parse(storedItems) : [];
    console.log("this.selectedItems", this.selectedItems);
    this.calculateSetAmtAll();
  },
  methods: {
    calculateSetAmtAll() {
      this.setAmtAll = this.selectedItems.reduce(
        (total, item) => total + item.setAmt,
        0
      );
    },
    deleteLottery(index) {
      this.selectedItems.splice(index, 1);
      localStorage.setItem("selectedItems", JSON.stringify(this.selectedItems));
      this.calculateSetAmtAll();
    },
  },
};
</script>

<style scoped>
.single-line-row {
  display: flex;
  align-items: center;
}

.custom-link {
  color: black;
  transition: color 0.3s; /* Add a smooth transition effect */
}

.custom-link:hover {
  color: #4ca3ad; /* Change to your primary color */
}

.highlighted-tab {
  background-color: #4ca3ad;
}
</style>
